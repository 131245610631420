import { ServiceBase } from './service-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationService extends ServiceBase {
    protected getApiEndpoint(): string {
        return null;
    }

    getConfig(clientId: string): Observable<AppConfigModel> {
        return this.http.get<AppConfigModel>(`/api/application/config/${clientId}`);
    }
}