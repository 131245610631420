import { ServiceBase } from './service-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SignInModel } from '../models/signin.model';

@Injectable()
export class UserService extends ServiceBase {
    protected getApiEndpoint(): string {
        return null;
    }

    authenticate(clientId: string, model: LoginModel): Observable<TwoFactorDataModel> {
        return this.http.post<TwoFactorDataModel>(`/api/user/authenticate/${clientId}`, model);
    }

    requestOtpCode(clientId: string, model: RequestOtpModel): Observable<any> {
        return this.http.post<any>(`/api/user/request-otp/${clientId}`, model);
    }

    signIn(clientId: string, model: SignInModel): Observable<any> {
        return this.http.post<any>(`/api/user/sign-in/${clientId}`, model);
    }
}
