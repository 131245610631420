import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationService } from 'src/app/shared/services/application.service';

@Injectable()
export class LoginConfigResolver implements Resolve<AppConfigModel> {
    constructor(private appService: ApplicationService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const returnUrl = route.queryParamMap.get('returnUrl');
        let url = new URL(decodeURI(returnUrl), window.location.origin);
        let clientId = url.searchParams.get('client_id');
        return this.appService.getConfig(clientId);
    }
}