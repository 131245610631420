import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export abstract class ServiceBase {
    protected abstract getApiEndpoint(): string;
    
    public constructor(protected http: HttpClient) { }

    protected buildPath(resource: string): string {
        return `${this.getApiEndpoint()}${resource}`;
    }
}