import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { UserService } from "src/app/shared/services/user.service";
import { CustomValidators } from "src/app/shared/validators/custom.validators";
import { finalize } from "rxjs/operators";
import { SslService } from "src/app/shared/services/ssl.service";

import {
  faEnvelope,
  faSms,
  faCheck,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  CountdownConfig,
  CountdownComponent,
  CountdownEvent,
  CountdownStatus,
} from "ngx-countdown";

import * as moment from "moment";
import { DeviceDetectorService } from "ngx-device-detector";
import { SignInModel } from "src/app/shared/models/signin.model";

declare var $: any;

export const ScreenView = {
  Login: 1,
  AuthType: 2,
  Target: 3,
  Code: 4,
};

export const AuthType = {
  Email: "Email",
  Sms: "Sms",
};

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @ViewChild("txtOab", { static: false }) txtOab: ElementRef;
  @ViewChild("txtCpf", { static: false }) txtCpf: ElementRef;
  @ViewChild("txtNascimento", { static: false }) txtNascimento: ElementRef;
  @ViewChild("txtSeguranca", { static: false }) txtSeguranca: ElementRef;
  @ViewChild("resendCodeCountDown", { static: false })
  countdown: CountdownComponent;

  readonly defaultErrorMessage = "Erro ao executar requisição.";

  faEnvelop = faEnvelope;
  faSms = faSms;
  faCheck = faCheck;
  faArrowLeft = faArrowCircleLeft;

  private returnUrl: string;
  private clientId: string;

  config: AppConfigModel;
  error: ValidationErrorModel = null;
  errorTarget: ValidationErrorModel = null;
  errorCode: ValidationErrorModel = null;
  form: FormGroup;
  formTarget: FormGroup;
  formCode: FormGroup;
  twoFactorData?: TwoFactorDataModel = null;
  twoFactorViewIsHidden: boolean = false;
  screenView = ScreenView;
  currentScreen: number = ScreenView.Login;
  authType = AuthType;
  selectedAuthType: string;
  selectedAuthTarget?: { key: string; value: string } = null;
  targetItems: { key: string; value: string }[] = [];
  resendEnabled: boolean = false;
  countDownCfg: CountdownConfig = {
    format: "mm:ss",
    demand: false,
  };
  requiresBirthdate = true;
  requiresSecurityCode = true;
  showCertificateError = false;
  showBypassKeyControl = false;
  private pressTimer: any;
  private readonly longPressDuration = 2000; // 2000ms for a long press

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private sslService: SslService,
    private formBuilder: FormBuilder,
    public deviceService: DeviceDetectorService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      inscNumber: ["", [Validators.required, Validators.maxLength(10)]],
      cpf: [
        "",
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
          CustomValidators.cpf,
        ],
      ],
      birthdate: [
        "",
        [Validators.required, Validators.maxLength(10), CustomValidators.data],
      ],
      securityCode: ["", [Validators.required, Validators.maxLength(8)]],
      infoCert: ["", []],
      bypassKey: [null, []],
    });

    this.formTarget = this.formBuilder.group({});

    this.formCode = this.formBuilder.group({
      code: [
        "",
        [Validators.required, Validators.maxLength(6), Validators.minLength(6)],
      ],
    });

    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl;
      let url = new URL(decodeURI(this.returnUrl), window.location.origin);
      this.clientId = url.searchParams.get("client_id");
    });

    this.route.data.forEach((data) => {
      this.config = data["config"];

      if (!this.config.formFields.includes("DTNC")) {
        this.form.controls["birthdate"].setValidators([]);
        this.requiresBirthdate = false;
      }

      if (!this.config.formFields.includes("NSEG")) {
        this.form.controls["securityCode"].setValidators([]);
        this.requiresSecurityCode = false;
      }
    });
  }

  getLoginModel(): LoginModel {
    let model = Object.assign({}, this.form.value) as LoginModel;

    if (this.requiresBirthdate) {
      model.birthdate = moment.utc(model.birthdate, "DD/MM/YYYY").format();
    } else {
      delete model.birthdate;
    }

    if (!this.requiresSecurityCode) {
      delete model.securityCode;
    }

    return model;
  }

  authenticate() {
    if (this.form.invalid) {
      return;
    }

    this.error = null;
    let model = this.getLoginModel();
    this.form.disable();

    this.userService
      .authenticate(this.clientId, model)
      .pipe(
        finalize(() => {
          this.form.enable();
        }),
      )
      .subscribe(
        (result) => {
          this.twoFactorData = result;

          if (
            this.twoFactorData.emailEnabled ||
            this.twoFactorData.smsEnabled
          ) {
            this.currentScreen = ScreenView.AuthType;
          } else {
            this.signIn();
          }
        },
        (obj) => {
          this.error = obj.error as ValidationErrorModel;
        },
      );
  }

  selectAuthType(type: string) {
    this.selectedAuthType = type;

    if (type == AuthType.Email) {
      this.targetItems = this.twoFactorData.emails;
    } else {
      this.targetItems = this.twoFactorData.phones;
    }

    this.currentScreen = ScreenView.Target;
  }

  sendCode() {
    if (this.selectedAuthTarget) {
      this.errorTarget = null;
      this.formTarget.disable();

      const loginModel = this.getLoginModel();

      this.userService
        .requestOtpCode(this.clientId, {
          idtEndeCrypt: this.selectedAuthTarget.key,
          sendMode: this.selectedAuthType,
          bypassKey: loginModel.bypassKey,
        })
        .pipe(
          finalize(() => {
            this.formTarget.enable();
          }),
        )
        .subscribe(
          (obj) => {
            let seconds = obj.canRequestAgainInMinutes * 60;
            this.countDownCfg.leftTime = seconds;
            this.countdown.config.leftTime = seconds;
            this.countdown.restart();
            this.resendEnabled = false;
            this.currentScreen = ScreenView.Code;
          },
          (obj) => {
            this.errorTarget = obj.error as ValidationErrorModel;

            if (this.errorTarget.errorCode == "code_already_sent") {
              if (this.errorTarget.data.canRequestAgainInMinutes) {
                let seconds =
                  this.errorTarget.data.canRequestAgainInMinutes * 60;
                this.countDownCfg.leftTime = seconds;
                this.countdown.config.leftTime = seconds;
                this.countdown.restart();
                this.resendEnabled = false;
              }

              if (this.errorTarget.data.target) {
                this.selectedAuthTarget = this.errorTarget.data.target;
              }

              this.errorTarget = null;
              this.currentScreen = ScreenView.Code;
            }
          },
        );
    }
  }

  signIn() {
    var code = this.formCode.controls.code.value;

    if (
      (this.twoFactorData.emailEnabled || this.twoFactorData.smsEnabled) &&
      !code
    ) {
      return;
    }

    let signInModel = Object.assign(
      {
        code: code,
        idtEndeCrypt:
          this.selectedAuthTarget !== null ? this.selectedAuthTarget.key : null,
        sendMode: this.selectedAuthType,
      },
      this.getLoginModel(),
    ) as SignInModel;

    this.errorCode = null;
    this.formCode.disable();

    this.userService
      .signIn(this.clientId, signInModel)
      .pipe(
        finalize(() => {
          this.formCode.enable();
        }),
      )
      .subscribe(
        () => {
          window.location.href = this.returnUrl;
        },
        (obj) => {
          this.errorCode = obj.error as ValidationErrorModel;
        },
      );
  }

  countDownFinish(event: CountdownEvent) {
    if (event.status == CountdownStatus.done) {
      this.resendEnabled = true;
    }
  }

  changeAuthOption() {
    this.resendEnabled = false;
    this.errorCode = null;
    this.errorTarget = null;
    this.selectedAuthTarget = null;
    this.selectedAuthType = null;
    this.currentScreen = ScreenView.AuthType;
  }

  retrieveCertifcateData() {
    this.form.disable();

    this.sslService
      .tryGetCertificateData()
      .pipe(
        finalize(() => {
          this.form.enable();
        }),
      )
      .subscribe(
        (obj) => {
          console.log(obj);

          this.form.setValue({
            inscNumber: obj.inscNumber,
            cpf: obj.cpf,
            securityCode: obj.securityCode,
            birthdate: moment.utc(obj.birthdate).format("DD/MM/YYYY"),
            infoCert: obj.infoCert,
            bypassKey: ""
          });

          this.authenticate();
        },
        (obj) => {
          this.showCertificateError = true;

          this.form.setValue({
            infoCert: "",
          });
        },
      );
  }

  onTextChanged(event: any) {
    if (event.target.value.length == event.target.maxLength) {
      if (event.target.id == "txt-oab") {
        this.txtCpf.nativeElement.focus();
      } else if (
        event.target.id == "txt-cpf" &&
        !this.form.controls.cpf.invalid
      ) {
        this.txtNascimento.nativeElement.focus();
      } else if (
        event.target.id == "txt-nascimento" &&
        !this.form.controls.birthdate.invalid
      ) {
        this.txtSeguranca.nativeElement.focus();
      }
    }
  }

  onInputFocus(event: any) {
    // Android
    if (this.isAndroid()) {
      event.target.scrollIntoView(false);
    }
  }

  isAndroid(): boolean {
    return this.deviceService
      .getDeviceInfo()
      .os.toLowerCase()
      .includes("android");
  }

  onMouseDown() {
    this.pressTimer = setTimeout(() => {
      this.onLongPress();
    }, this.longPressDuration);
  }

  onMouseUp() {
    clearTimeout(this.pressTimer);
  }

  onMouseLeave() {
    clearTimeout(this.pressTimer);
  }

  onLongPress() {
    this.showBypassKeyControl = true;
  }
}
