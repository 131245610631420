import { ServiceBase } from './service-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SslService extends ServiceBase {
    protected getApiEndpoint(): string {
        return environment.certUrl;
    }

    tryGetCertificateData(): Observable<LoginModel> {
        return this.http.get<LoginModel>(this.buildPath('/ssl'));
    }
}