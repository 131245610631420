import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  LOAD_BAR_INCLUDE_SPINNER = false;
  LOAD_BAR_HEIGHT = '2px';
  LOAD_BAR_COLOR = '#ff1301';
  title = 'Login Integrado OAB';

  ngOnInit(): void {
    /*if(environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }*/
  }
}
