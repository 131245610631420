import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ErrorComponent } from './pages/error/error.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NumbersOnlyDirective } from './shared/directives/numbers-only.directive';
import { ApplicationService } from './shared/services/application.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginConfigResolver } from './pages/login/login-config.resolver';
import { UserService } from './shared/services/user.service';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CountdownModule } from 'ngx-countdown';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { SslService } from './shared/services/ssl.service';
import { DeviceDetectorModule } from 'ngx-device-detector';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

registerLocaleData(localeBr, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ErrorComponent,
        LogoutComponent,
        NumbersOnlyDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FontAwesomeModule,
        CountdownModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        NgxMaskModule.forRoot(options),
        DeviceDetectorModule.forRoot()
    ],
    providers: [
        ApplicationService,
        UserService,
        LoginConfigResolver,
        SslService,
        { provide: APP_BASE_HREF, useValue: environment.baseUrl }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        moment.locale('pt-BR');
    }
}
