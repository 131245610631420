import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ErrorComponent } from './pages/error/error.component';
import { LoginConfigResolver } from './pages/login/login-config.resolver';


const routes: Routes = [
    { path: 'login', component: LoginComponent, resolve: { config: LoginConfigResolver } },
    { path: 'error', component: ErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
